export const AppImages = {
    placeholder: require("../assets/profile-placeholder.png"),
    plusIcon: require("../assets/posts-plus.png"),
    like: require("../assets/like.png"),
    comments: require("../assets/comments.png"),
    likedIcon: require("../assets/liked-icon.png"),
    upArrowIcon: require("../assets/up-arrow-icon.png"),
    heartIcon: require("../assets/heart-icon.png"),
    replyIcon: require("../assets/reply-icon.png"),
    helpAndFeedback: require("../assets/help-and-feedback.png"),
    threeDotsMenu: require("../assets/three-dots.png"),
    reportIcon: require("../assets/red-exclamation-mark.png"),
    playIcon: require("../assets/play-button.png"),
    emojis: {
        Angry: require("../assets/emojis/angry.png"),
        Fav: require("../assets/emojis/fav.png"),
        Happy: require("../assets/emojis/happy.png"),
        InLove: require("../assets/emojis/fav.png"),
        Like: require("../assets/emojis/like1.png"),
        Mad: require("../assets/emojis/mad.png"),
        Sad: require("../assets/emojis/sad.png"),
        Smile: require("../assets/emojis/smile.png"),
        Surprised: require("../assets/emojis/surprised.png"),
    },
    showPass: require("../assets/showPass.png"),
    hidePass: require("../assets/hidePass.png"),
    close: require("../assets/close.png")
}